import React from "react";
import { MDXProvider } from "@mdx-js/react";
// import { Outlink } from "./styles/shared";

const components = {
  // a: Outlink,
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);

import styled from "@emotion/styled";
import { Link } from "gatsby";

export const LinkCss = (theme) => ({
  fontSize: theme.fontSizes[3],
  color: theme.colors.text,
  textDecoration: `none`,
  backgroundImage: `linear-gradient(120deg, ${theme.colors.accent} 0%, #fee76a 100%)`,
  backgroundRepeat: `no-repeat`,
  backgroundSize: `100% 0.2em`,
  backgroundPosition: `0 88%`,
  transition: `all 0.25s ease-in`,
  "&:hover": {
    backgroundSize: `100% 88%`,
    color: `#1d1e22`,
  },
});

const linkStyle = `
color: #000;
text-decoration: none;
background-image: linear-gradient(120deg, #feda6a 0%, #fee76a 100%);
background-repeat: no-repeat;
background-size: 100% 0.2em;
background-position: 0 88%;
transition: all 0.25s ease-in;
&:hover {
  background-size: 100% 88%;
  color: #1d1e22;
}
`;

export const Outlink = styled(`a`)`
  ${linkStyle}
`;

export const InternalLink = styled(Link)`
  ${linkStyle}
`;

/** @jsx jsx */
import { jsx } from "theme-ui";

const BaseDateTimeCss = {
    background: `#f1f3f5`,
    color: `#343a40`,
    margin: `0.2rem`,
    padding: `0.4rem 0.6rem`,
    fontSize: `0.8rem`,
    borderRadius: `3rem`,
    borderBottom: 0,
    whiteSpace: `nowrap`,
    lineHeight: 1,
};

const DateTime = ({ dateTime, date, ...rest }) => {
    return (
        <time css={BaseDateTimeCss} dateTime={dateTime}>
            <span css={{ display: `inline-block`, minWidth: `40px`, textAlign: `center` }}>
                {date}
            </span>
        </time>
    );
};

export default DateTime;

/** @jsx jsx */
import { jsx, Styled, useThemeUI } from "theme-ui";
import styled from "@emotion/styled";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { Nav } from "./";
import { Outlink, LinkCss } from "../styles/shared";
import { Link } from "gatsby";
// import Emoji from "a11y-react-emoji";
import "typeface-libre-franklin";

const MainWrapper = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;

  @media (max-width: 1400px) {
    padding: 0 2rem;
  }
`;

const ContentWrapper = styled(`div`)`
  margin: 0 auto;
  max-width: 48rem;
  width: 100%;

  @media (max-width: 1200px) {
    max-width: 40rem;
  }
`;

const Header = styled(`header`)`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Main = styled(`main`)`
  margin: 2.4rem auto;
`;

const Footer = styled(`footer`)`
  margin: 1rem auto;
  text-align: center;
`;

const Layout = ({ children }) => {
  const { theme } = useThemeUI();
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={(data) => (
        <MainWrapper>
          <ContentWrapper>
            <Header>
              <Link css={LinkCss(theme)} to={`/`}>
                Amberley Romo
              </Link>
              <Nav menuLinks={data.site.siteMetadata.menuLinks} />
            </Header>
          </ContentWrapper>

          <ContentWrapper>
            <Main>{children}</Main>
          </ContentWrapper>

          <ContentWrapper>
            <Footer>
              <Styled.p>
                © {new Date().getFullYear()} Amberley Romo
              </Styled.p>
            </Footer>
          </ContentWrapper>
        </MainWrapper>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useThemeUI } from "theme-ui";
import { InternalLink } from "../styles/shared";

const ListCss = {
  display: `flex`,
  justifyContent: `center`,
  listStyle: `none`,
  padding: 0,
  margin: 0,
};

const ListItemCss = (theme) => ({
  fontSize: theme.fontSizes[3],
  padding: `0 0.6rem`,
});

const Nav = ({ menuLinks }) => {
  const { theme } = useThemeUI();

  return (
    <nav>
      <ul css={ListCss}>
        {menuLinks.map((link) => (
          <li key={link.name} css={ListItemCss(theme)}>
            <InternalLink to={link.link}>{link.name}</InternalLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;

/** @jsx jsx */
import { jsx } from "theme-ui";

const BaseTagCss = {
  background: `#077C76`,
  color: `#fff`,
  margin: `0.2rem`,
  padding: `0.4rem 0.6rem`,
  fontSize: `0.8rem`,
  borderRadius: `3rem`,
  borderBottom: 0,
  whiteSpace: `nowrap`,
  lineHeight: 1,
};

const Tag = ({ tag, ...rest }) => {
  return (
    <span css={BaseTagCss} {...rest}>
      {tag}
    </span>
  );
};

export default Tag;

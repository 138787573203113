/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";

const CardCss = {
  background: `#fff`,
  transition: `all 150ms ease`,
  border: "1px solid rgba(52, 61, 68, 0.05)",
  padding: `0.4em 1.2em 0.8em 1.2em`,
  margin: `0 auto 2rem`,
  "&:hover": {
    transform: "scale(1.015)",
    boxShadow: "0 10px 30px -10px rgba(0,0,0,0.15)",
    p: {
      transition: "all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;",
    },
  },
};

const BorderTopVariantCss = (theme) => ({
  borderTop: "4px solid rgba(52, 61, 68, 0.05)",
  borderRadius: "0px 0px 6px 6px",
  "&:hover": {
    borderTop: `4px solid ${theme.colors.secondary}`,
  },
});

const BorderLeftVariantCss = (theme) => ({
  borderLeft: "4px solid rgba(52, 61, 68, 0.05)",
  borderRadius: "0px 6px 6px 0px",
  "&:hover": {
    borderLeft: `4px solid ${theme.colors.secondary}`,
  },
});

const Card = ({ children, as: Component = `div`, border = `top`, ...rest }) => {
  const { theme } = useThemeUI();
  return (
    <Component
      css={[
        CardCss,
        border === `top` && BorderTopVariantCss(theme),
        border === `left` && BorderLeftVariantCss(theme),
      ]}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Card;

import { merge } from "theme-ui";
import { base } from "@theme-ui/presets";
import nightOwl from "@theme-ui/prism/presets/night-owl";

const theme = merge(base, {
  initialColorModeName: `light`,
  useCustomProperties: true,
  colors: {
    text: "#313131",
    background: "#fffefb",
    primary: "#c94327",
    secondary: "#3AAFA9",
    darken: "#B02A0E",
    muted: "#f6f6f6",
    highlight: "#DEF2F1",
    gray: "#747474",
    accent: "#FEDA6A",
    modes: {
      dark: {
        text: "#ffffff",
        background: "#343434",
        primary: "#33ccff",
        secondary: "#ee00ff",
        muted: "#191919",
        highlight: "#29112c",
        gray: "#999999",
        accent: "#cc00ff",
      },
    },
  },
  fonts: {
    heading: `"Libre Franklin", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
    body: `"Libre Franklin", -apple-system, BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Times,"Times New Roman",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  },
  fontSizes: [12, 15, 18, 22, 26, 32, 38, 46],
  styles: {
    root: {
      color: `text`,
      backgroundColor: `background`,
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
    },
    a: {
      color: "primary",
      textDecoration: `underline`,
      ":hover": {
        color: "darken",
      },
      ":focus": {
        color: "darken",
      },
    },
    p: {
      fontSize: [2, 2, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
    ul: {
      li: {
        fontSize: [2, 2, 2],
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
      },
    },
    ol: {
      li: {
        fontSize: [2, 2, 2],
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
      },
    },
    h1: {
      variant: `text.heading`,
      fontSize: [5, 6, 7],
      mt: 4,
    },
    h2: {
      variant: `text.heading`,
      fontSize: [4, 5, 6],
      mt: 4,
    },
    h3: {
      variant: `text.heading`,
      fontSize: [3, 4, 5],
      mt: 4,
    },
    h4: {
      variant: `text.heading`,
      fontSize: [2, 3, 4],
      mt: 3,
    },
    h5: {
      variant: `text.heading`,
      fontSize: [1, 2, 3],
      mt: 3,
    },
    h6: {
      variant: `text.heading`,
      fontSize: 1,
      mb: 2,
    },
    blockquote: {
      borderLeftColor: `primary`,
      borderLeftStyle: `solid`,
      borderLeftWidth: `6px`,
      mx: 0,
      pl: 4,
      p: {
        fontStyle: `italic`,
      },
    },
    table: {
      width: `100%`,
      my: 4,
      borderCollapse: `separate`,
      borderSpacing: 0,
      [[`th`, `td`]]: {
        textAlign: `left`,
        py: `4px`,
        pr: `4px`,
        pl: 0,
        borderColor: `muted`,
        borderBottomStyle: `solid`,
      },
    },
    th: {
      verticalAlign: `bottom`,
      borderBottomWidth: `2px`,
      color: `text`,
    },
    td: {
      verticalAlign: `top`,
      borderBottomWidth: `1px`,
    },
    hr: {
      mx: 0,
    },
    pre: {
      ...nightOwl,
      marginBottom: 2,
      overflow: `auto`,
      borderRadius: 6,
      p: 4,
      ".highlight": {
        background: `hsla(0, 0%, 40%, .5)`,
      },
      ".comment": {
        color: "rgb(121, 135, 135)",
        fontStyle: "italic",
      },
    },
    code: {
      fontFamily: `monospace`,
      fontSize: `inherit`,
    },
    inlineCode: {
      borderRadius: `0.3em`,
      color: `text`,
      bg: `accent`,
      paddingTop: `0.05em`,
      paddingBottom: `0.1em`,
      paddingX: `0.2em`,
    },
  },
  layout: {
    container: {
      padding: [3, 4],
      maxWidth: `1024px`,
    },
  },
  text: {
    heading: {
      fontFamily: `heading`,
      fontWeight: `heading`,
      lineHeight: `heading`,
      color: `text`,
    },
  },
  //   links: {
  //     secondary: {
  //       color: `secondary`,
  //       textDecoration: `none`,
  //       ":hover": {
  //         color: `text`,
  //         textDecoration: `underline`,
  //       },
  //       ":focus": {
  //         color: `text`,
  //       },
  //     },
  //     listItem: {
  //       fontSize: [1, 2, 3],
  //       color: `text`,
  //     },
  //   },
});

export default theme;
